"use strict";

// Class definition
var KTScrollable = function () {
    
    // Private functions

    // basic demo
    var demo1 = function () {
    }

    return {
        // public functions
        init: function() {
            demo1();
        }
    };
}();

jQuery(document).ready(function() {    
    KTScrollable.init();
});