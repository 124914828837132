"use strict";

// Class definition
var KTjVectorMap = function() {

    // Private functions

    var demo1 = function() {
    }

    return {
        // public functions
        init: function() {
            // default charts
            demo1();
        }
    };
}();

jQuery(document).ready(function() {
    KTjVectorMap.init();
});